import axios from 'utils/axios';
import { all, call, fork, put, retry, takeLatest, select } from 'redux-saga/effects';
import { sagaErrorHandler } from 'shared/helperMethods/sagaErrorHandler';
import { makeSelectAuthToken } from '../../shared/helperMethods/Selectors';
import { getAllUsersSuccess, getAllUsers } from './actions';
import { GET_ALL_USERS, ADD_GUEST_USER, DELETE_USER, CHANGE_USER_STATUS, ADD_RESTRICTED_USER, CHANGE_USER_STATUS_CHAT } from './constants';
import { SetNotification } from 'shared/helperMethods/setNotification';

function* addRestrictedUserRequest({ payload }) {
  try {
    const data = { headers: { Authorization: `Bearer ${yield select(makeSelectAuthToken())}` } };
    const response = yield axios.put(`admin/users/blockUser/${payload.walletAddress}`, data, {
      headers: {
        Authorization: `Bearer ${yield select(makeSelectAuthToken())}`
      }
    });
    yield put(
      getAllUsers({
        search: payload.search,
        page: payload.page,
        limit: payload.limit,
        type: payload.type
      })
    );
    payload.handleClose();
    yield SetNotification('success', response.data.message);
  } catch (error) {
    yield sagaErrorHandler(error.response.data.data);
  }
}

export function* watchAddRestrictedUser() {
  yield takeLatest(ADD_RESTRICTED_USER, addRestrictedUserRequest);
}

function* getAllUsersRequest({ payload }) {
  // try {
  //     const axios = require('axios');
  //     let body = '';

  //     let config = {
  //         method: 'get',
  //         maxBodyLength: Infinity,
  //         url: `http://localhost:5000/api/v1/admin/users/search?username=${payload.search}`,
  //         headers: {
  //             Authorization:
  //                 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTgsImlhdCI6MTY4NTk3NDUwMn0.pL13iHFvpjOXDF2AJaDjFf886SDRQtLFhSR8aZAXI0M'
  //         },
  //         data: body
  //     };

  //     const { data } = yield axios.request(config);
  //     yield put(getAllUsersSuccess(data.data));
  // } catch (error) {
  //     console.log(error, 'error************');
  // }
  try {
    const headers = { headers: { Authorization: `Bearer ${yield select(makeSelectAuthToken())}` } };

    const response = yield axios.get(
      `/admin/users/search?username=${payload.search}&page=${payload.page}&size=${payload.limit}&type=${payload.type}&accountType=${payload.accountType}&teamName=${payload.teamName}`,
      headers
    );
    yield put(getAllUsersSuccess(response.data.data));
  } catch (error) {
    console.log(error, 'error************');
  }
}

export function* watchGetAllUsers() {
  yield takeLatest(GET_ALL_USERS, getAllUsersRequest);
}

function* addGuestUserRequest({ payload }) {
  let data = {
    walletAddress: payload.walletAddress
  };
  try {
    const headers = { headers: { Authorization: `Bearer ${yield select(makeSelectAuthToken())}` } };
    const response = yield axios.post(`admin/users/guest`, data, headers);
    yield put(
      getAllUsers({
        search: payload.search,
        page: payload.page,
        limit: payload.limit,
        type: payload.type
      })
    );
    payload.handleClose();
    yield SetNotification('success', response.data.message);
  } catch (error) {
    yield sagaErrorHandler(error.response.data.data);
  }
}

export function* watchAddGuestUser() {
  yield takeLatest(ADD_GUEST_USER, addGuestUserRequest);
}

function* deleteUserRequest({ payload }) {
  try {
    const headers = { headers: { Authorization: `Bearer ${yield select(makeSelectAuthToken())}` } };
    const response = yield axios.delete(`/admin/users/delete/${payload.id}`, headers);
    yield put(
      getAllUsers({
        search: '',
        username: '',
        page: 1,
        limit: 10,
        type: 'all',
        accountType: 'all',
        teamName: 'all'
      })
    );
    payload.handleClose();
    console.log('response delete saga', response);
    yield SetNotification('success', response.data.message);
  } catch (error) {
    console.log('error delete saga', error);
    yield sagaErrorHandler(error.response.data.data);
  }
}

export function* watchDeleteUser() {
  yield takeLatest(DELETE_USER, deleteUserRequest);
}

function* blockUserRequest({ payload }) {
  try {
    const headers = { headers: { Authorization: `Bearer ${yield select(makeSelectAuthToken())}` } };
    const response = yield axios.post(`/admin/chats/disable/${payload.id}`, {}, headers);
    // const response = yield axios.post(`/admin/block/user/${payload.id}`, {}, headers);
    yield put(
      getAllUsers({
        search: '',
        username: '',
        page: 1,
        limit: 10,
        type: 'all',
        accountType: 'all',
        teamName: 'all'
      })
    );
    yield SetNotification('success', response.data.message);
  } catch (error) {
    yield sagaErrorHandler(error.response.data.data);
  }
}

export function* watchChangeStatus() {
  yield takeLatest(CHANGE_USER_STATUS, blockUserRequest);
}

function* chatUserRequest({ payload }) {
  try {
    const headers = { headers: { Authorization: `Bearer ${yield select(makeSelectAuthToken())}` } };
    // const response = yield axios.post(`/admin/block/user/${payload.id}`, {}, headers);
    const response = yield axios.post(`/admin/chats/disable/${payload.id}`, {}, headers);
    yield put(
      getAllUsers({
        search: '',
        username: '',
        page: 1,
        limit: 10,
        type: 'all',
        accountType: 'all',
        teamName: 'all'
      })
    );
    yield SetNotification('success', response.data.message);
  } catch (error) {
    yield sagaErrorHandler(error.response.data.data);
  }
}

export function* watchChangeStatusChat() {
  yield takeLatest(CHANGE_USER_STATUS_CHAT, chatUserRequest);
}

export default function* usersSaga() {
  yield all([
    fork(watchGetAllUsers),
    fork(watchAddGuestUser),
    fork(watchDeleteUser),
    fork(watchChangeStatus),
    fork(watchAddRestrictedUser),
    fork(watchChangeStatusChat)
  ]);
}