import axioss from 'axios';
// export const API_URL = 'http://182.176.170.176:1441/api/v1'; // Live
export const API_URL = 'https://easytipping.com/api/v1'; // Live
// export const API_URL = 'http://localhost:5000/api/v1'; // sohaib ip
//--openssl-legacy-provider
const axios = axioss.create({
  baseURL: API_URL,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest'
  }
});

axios.interceptors.response.use(
  function (response) {
    // Do something with response data

    return response;
  }
  // , function (error, response) {
  //   if(error.response.status === 401){
  //     localStorage.removeItem('token');
  //     localStorage.removeItem('role');
  //     window.location = '/'
  //     }
  // }
);

export default axios;
